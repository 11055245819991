import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getOneCamp } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class DetailsCamps extends Component {
  state = {
    name: null,
    camp_details: null,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_ROLE" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      // get the role from route param
      this.props.getOneCamp(id).then((res) => {
        if (res.status === "success") {
          console.log(res.payload);
          this.setState({
            name: res.payload.camp.name,
            camp_id: res.payload.camp.id,
            camp_details: res.payload.camp.users,
          });
        }
      });
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  getMemberStatus = (status) => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "";
  };
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };

  render() {
    const { spinner, status, message } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.name}</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                <span className="text-capitalize ">{this.state.name}</span>{" "}
                Member(s) Details
              </h3>
              <div className="card-tools">
                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_CAMP",
                }) ? (
                  <Link to="/preferences/camps/">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-long-arrow-alt-left mr-1"></i>
                      BACK
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="card-body">
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              {spinner ? (
                <Spinner position="right" />
              ) : (
                <div>
                  <table
                    id="camp_detail_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Last name</th>
                        <th>First name</th>
                        <th>Other name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>type</th>
                        <th>Status</th>
                        <th>Last Payment Date</th>
                        <th>Expiry Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.camp_details &&
                        this.state.camp_details.map((camp, index) => (
                          <tr key={index}>
                            <td>{camp.last_name}</td>
                            <td>{camp.first_name}</td>
                            <td>{camp.other_name ? camp.other_name : "--"}</td>
                            <td>{camp.phone.trim()}</td>
                            <td>{camp.email ? camp.email : "--"}</td>
                            <td>
                              {camp.type === "G" ? "GIDEON" : "AUXILLIARY"}
                            </td>
                            <td>{this.getMemberStatus(camp.status)}</td>
                            <td>{this.dateConv(camp.last_payment_date)}</td>
                            <td>{this.dateConv(camp.expiry_date)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}

            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    message: state.preferences.message,
    spinner: state.preferences.spinner,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOneCamp: (id) => getOneCamp(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsCamps);
