import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAuthorizedRequest,
  acknowlegdeRequest,
  resetUsersState,
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import Pagination from "react-js-pagination";
import { getAllCamps } from "../../../../store/actions/preferencesActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import classnames from "classnames";

class ViewAuthorizedRequest extends Component {
  state = {
    activePage: 1,
    modal: false,
  };
  componentDidMount() {
    const { setPermissionsErrors, getAuthorizedRequest } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_RELOCATION" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      let years_arry = [];
      const start_year = 2020;
      const end_year = new Date().getFullYear() + 2;
      for (let i = start_year; i < end_year; i++) {
        years_arry.push(i);
      }
      this.setState({ years_arry });
      getAuthorizedRequest().then((res) => {
        if (res.status === "success") {
          //   console.log(res);
          this.setState({ ...res.payload.relocation });
        }
      });
      this.props.getAllCamps();
    }
  }
  dateConv = (date) => {
    return new Date(date).toDateString();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.camps !== prevState.camps) {
      return {
        camps: nextProps.camps,
      };
    }
    return null;
  }
  cancelSearch = () => {
    this.setState(
      {
        year_pag: "",
        month_pag: "",
        day_pag: "",
      },
      () => {
        this.handleSearchChange();
      }
    );
  };
  handleSearchChange = () => {
    const pageNumber = 1;
    this.handlePageChange(pageNumber);
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    const { getAuthorizedRequest } = this.props;
    const year = this.state.year_pag;
    const month = this.state.month_pag;
    const day = this.state.day_pag;
    getAuthorizedRequest(pageNumber, year, month, day).then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.relocation });
      }
    });
  };
  acknowlegdeRequest = (e, id) => {
    e.preventDefault();
    this.setState({ selected_id: id, camp_id: "", modal: true });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const creds = {
      id: this.state.selected_id,
      camp_id: this.state.camp_id,
    };
    this.props.acknowlegdeRequest(creds).then((res) => {
      if (res.status === "success") {
        const tempdata = [...this.state.data];
        this.setState({
          data: tempdata.filter((data) => data.id !== creds.id),
          modal: false,
          selected_id: "",
          camp_id: "",
        });
      }
    });
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  closeModal = (e) => {
    this.setState({ modal: false, camp_id: "", selected_id: "" });
    this.props.resetUsersState();
  };
  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    // if (!spinner && status !== "error") {
    //   this.initDependency();
    // }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>MEMBERSHIP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Authorized Relocation Request(s)
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Authorized Relocation Request(s)
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {this.state.modal ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "10%",
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#fff",
                      height: "300px",
                    }}
                  >
                    <form onSubmit={this.handleSubmit}>
                      <h4 className="text-center mt-3">Select New Camp</h4>
                      <div className="col-sm-6 offset-sm-3 ">
                        <div className="form-group">
                          <label htmlFor="camp_id">
                            Camp <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="d-flex">
                            <select
                              className={classnames({
                                "is-invalid": this.hasErrorFor("camp_id"),
                                "form-control": true,
                              })}
                              placeholder="Select a Camp"
                              onChange={this.handleChange}
                              value={this.state.camp_id}
                              id="camp_id"
                              name="camp_id"
                            >
                              <option value="">--Select a camp--</option>
                              <option value="trans">**Out of Country**</option>
                              {this.state.camps &&
                                this.state.camps.map((camp, index) => (
                                  <option key={index} value={camp.id}>
                                    {camp.name}
                                  </option>
                                ))}
                            </select>
                            {preference_spinner ? <MiniSpinner /> : null}
                          </div>
                          {this.renderErrorFor("camp_id")}
                        </div>

                        <button
                          type="submit"
                          className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                        >
                          Authorize
                          {spinner ? <MiniSpinner color="white" /> : null}
                        </button>
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                          onClick={this.closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}

              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <div className="form-group col-md-3 d-flex">
                    <select
                      id="year_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.year_pag || ""}
                    >
                      <option value="">Year</option>
                      {this.state.years_arry &&
                        this.state.years_arry.map((year) => (
                          <option key={year} value={year}>
                            {" "}
                            {year}
                          </option>
                        ))}
                    </select>
                    <select
                      id="month_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.month_pag || ""}
                    >
                      <option value="">Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <select
                      id="day_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.day_pag || ""}
                    >
                      <option value="">Day</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8"> 8</option>
                      <option value="9"> 9</option>
                      <option value="10"> 10</option>
                      <option value="11"> 11</option>
                      <option value="12"> 12</option>
                      <option value="13"> 13</option>
                      <option value="14"> 14</option>
                      <option value="15"> 15</option>
                      <option value="16"> 16</option>
                      <option value="17"> 17</option>
                      <option value="18"> 18</option>
                      <option value="19"> 19</option>
                      <option value="20"> 20</option>
                      <option value="21"> 21</option>
                      <option value="22"> 22</option>
                      <option value="23"> 23</option>
                      <option value="24"> 24</option>
                      <option value="25"> 25</option>
                      <option value="26"> 26</option>
                      <option value="27"> 27</option>
                      <option value="28"> 28</option>
                      <option value="29"> 29</option>
                      <option value="30"> 30</option>
                      <option value="31"> 31</option>
                    </select>
                    <button onClick={this.handleSearchChange}>
                      <i className="fa fa-search text-primary"></i>
                    </button>
                    <button onClick={this.cancelSearch}>
                      <i className="fa fa-times text-danger"></i>
                    </button>
                  </div>
                  <table
                    id="state_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Members</th>
                        <th>Former Camp</th>
                        <th>Proposed Camp</th>
                        <th> Asigned Camp</th>
                        <th>Country</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Address</th>
                        <th>Reason for relocation</th>
                        <th>Date Created</th>
                        <th>Date Authorized </th>
                        <th> Authorized By </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {item.user.first_name} {item.user.last_name}{" "}
                                {item.user.other_name}{" "}
                              </td>

                              <td>{item.old_camp.name}</td>
                              <td>{item.proposed_camp?.name}</td>
                              <td>
                                {item.new_camp
                                  ? item.new_camp.name
                                  : "Out Of Country"}
                              </td>

                              <td>{item.country}</td>
                              <td>{item.state}</td>
                              <td>{item.city}</td>
                              <td>{item.address}</td>
                              <td>{item.info}</td>
                              <td>{this.dateConv(item.created_at)}</td>
                              <td>{this.dateConv(item.authorised_date)}</td>
                              <td>
                                {item.authorised_by.first_name}{" "}
                                {item.authorised_by.last_name}{" "}
                                {item.authorised_by.other_name}{" "}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    errors: state.users.errors,
    message: state.users.message,
    status: state.users.status,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAuthorizedRequest: (page, year, month, day) =>
      getAuthorizedRequest(dispatch, page, year, month, day),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    acknowlegdeRequest: (id) => acknowlegdeRequest(dispatch, id),
    getAllCamps: () => dispatch(getAllCamps()),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAuthorizedRequest);
