import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPendingRenewal,
  rejectRenewal,
  acknowlegdeRenewal,
  resetUsersState,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import Pagination from "react-js-pagination";
// import { getAllCamps } from "../../../../store/actions/preferencesActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import classnames from "classnames";
import { image_path } from "./../../../../config";

class PendingRenewal extends Component {
  state = {
    activePage: 1,
    modal: false,
    modal_2: false,
    comment: "",
  };
  componentDidMount() {
    const { setPermissionsErrors, getPendingRenewal } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_PAYMENTS" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      let years_arry = [];
      const start_year = 2020;
      const end_year = new Date().getFullYear() + 2;
      for (let i = start_year; i < end_year; i++) {
        years_arry.push(i);
      }
      this.setState({ years_arry });
      getPendingRenewal().then((res) => {
        // console.log("jj",res);
        if (res.status === "success") {
          this.setState({ ...res.payload.renewals });
        }
      });
      //   this.props.getAllCamps();
    }
  }
  dateConv = (date) => {
    return new Date(date).toDateString();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  cancelSearch = () => {
    this.setState(
      {
        year_pag: "",
        month_pag: "",
        day_pag: "",
      },
      () => {
        this.handleSearchChange();
      }
    );
  };
  handleSearchChange = () => {
    const pageNumber = 1;
    this.handlePageChange(pageNumber);
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    const { getPendingRenewal } = this.props;
    const year = this.state.year_pag;
    const month = this.state.month_pag;
    const day = this.state.day_pag;
    getPendingRenewal(pageNumber, year, month, day).then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.renewals });
      }
    });
  };
  acknowlegdeRenewal = (e, id) => {
    e.preventDefault();
    // this.setState({ selected_id: id, camp_id: "", modal: true });
    if (window.confirm("Are you sure you have verified this payment?")) {
      this.props.acknowlegdeRenewal(id).then((res) => {
        if (res.status === "success") {
          const tempdata = [...this.state.data];
          this.setState({
            data: tempdata.filter((data) => data.id !== id),
          });
          window.alert(`${res.message}`);
        } else {
          window.alert(`${res.status}`);
        }
      });
    }
  };
  rejectRenewal = (e, id) => {
    e.preventDefault();
    // this.setState({ selected_id: id, camp_id: "", modal: true });
    if (window.confirm("Are you sure you reject this payment?")) {
      this.setState({ modal_2: true, selected_id: id });
    }
  };
  viewImage = (e, id) => {
    e.preventDefault();
    this.setState(
      { selected_member: this.state.data.find((item) => item.id === id) },
      () => this.setState({ modal: true })
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const creds = {
      id: this.state.selected_id,
      comment: this.state.comment,
    };
    this.props.rejectRenewal(creds).then((res) => {
      if (res.status === "success") {
        const tempdata = [...this.state.data];
        this.setState({
          data: tempdata.filter((data) => data.id !== creds.id),
          modal_2: false,
          selected_id: null,
          comment: "",
        });
        window.alert(`${res.message}`);
      } else {
        window.alert(`${res.status}`);
      }
    });
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  closeModal = (e) => {
    this.setState({ modal: false, selected_member: null });
    this.props.resetUsersState();
  };
  closeModal_2 = (e) => {
    this.setState({ modal_2: false, comment: "" });
    this.props.resetUsersState();
  };
  getType = (type) => {
    if (type === "G") {
      return "GIDEON";
    } else if (type === "A") {
      return "AUXILLIARY";
    } else if (type === "C") {
      return "COUPLE";
    }
    return null;
  };
  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Pending Renewal</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Pending Renewal</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {this.state.modal ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "2%",
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div className="card-body">
                    <div
                      className="row col-sm-12"
                      style={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <div className="col-sm-6 offset-sm-3  ">
                        <h5 className="text-center mt-3">
                          {" "}
                          Evidence of Payment for{" "}
                          {this.state.selected_member.user.first_name}{" "}
                          {this.state.selected_member.user.last_name}{" "}
                          {this.state.selected_member.user.other_name}
                        </h5>
                        {this.state.selected_member.evidence ? (
                          <img
                            className="img-fluid"
                            src={`${image_path}${this.state.selected_member.evidence}`}
                            alt=""
                          />
                        ) : (
                          <p>No payment evidence provided</p>
                        )}
                        <br />
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold my-2"
                          onClick={this.closeModal}
                        >
                          Close
                        </button>{" "}
                        {this.state.selected_member.evidence ? (
                          <a
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold my-2"
                            // onClick={this.downloadEvidence}
                            href={`${image_path}${this.state.selected_member.evidence}`}
                            download="evidence_of_payment"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}

              {this.state.modal_2 ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    padding: "5%",
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#fff",
                      height: "300px",
                    }}
                  >
                    <form onSubmit={this.handleSubmit}>
                      <h4 className="text-center mt-3">
                        {" "}
                        Enter Rejection Reason
                      </h4>
                      <div className="col-sm-6 offset-sm-3 ">
                        <div className="form-group">
                          <label htmlFor="comment">
                            Comment <span className="text-danger">*</span>{" "}
                          </label>
                          <textarea
                            className={classnames({
                              "is-invalid": this.hasErrorFor("comment"),
                              "form-control": true,
                            })}
                            id="comment"
                            name="comment"
                            rows={2}
                            onChange={this.handleChange}
                            value={this.state.comment}
                          />

                          {this.renderErrorFor("comment")}
                        </div>

                        <button
                          type="submit"
                          className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                        >
                          Reject
                          {spinner ? <MiniSpinner color="white" /> : null}
                        </button>
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                          onClick={this.closeModal_2}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}

              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  {message && status ? (
                    <Message message={message} status={status} />
                  ) : null}
                  <div className="form-group col-md-3 d-flex">
                    <select
                      id="year_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.year_pag || ""}
                    >
                      <option value="">Year</option>
                      {this.state.years_arry &&
                        this.state.years_arry.map((year) => (
                          <option key={year} value={year}>
                            {" "}
                            {year}
                          </option>
                        ))}
                    </select>
                    <select
                      id="month_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.month_pag || ""}
                    >
                      <option value="">Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <select
                      id="day_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.day_pag || ""}
                    >
                      <option value="">Day</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8"> 8</option>
                      <option value="9"> 9</option>
                      <option value="10"> 10</option>
                      <option value="11"> 11</option>
                      <option value="12"> 12</option>
                      <option value="13"> 13</option>
                      <option value="14"> 14</option>
                      <option value="15"> 15</option>
                      <option value="16"> 16</option>
                      <option value="17"> 17</option>
                      <option value="18"> 18</option>
                      <option value="19"> 19</option>
                      <option value="20"> 20</option>
                      <option value="21"> 21</option>
                      <option value="22"> 22</option>
                      <option value="23"> 23</option>
                      <option value="24"> 24</option>
                      <option value="25"> 25</option>
                      <option value="26"> 26</option>
                      <option value="27"> 27</option>
                      <option value="28"> 28</option>
                      <option value="29"> 29</option>
                      <option value="30"> 30</option>
                      <option value="31"> 31</option>
                    </select>
                    <button onClick={this.handleSearchChange}>
                      <i className="fa fa-search text-primary"></i>
                    </button>
                    <button onClick={this.cancelSearch}>
                      <i className="fa fa-times text-danger"></i>
                    </button>
                  </div>
                  <table
                    id="state_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Members</th>
                        <th>Amount ( &#8358; )</th>
                        <th>Type</th>
                        <th>Year(s)</th>
                        <th>Payment Type</th>
                        <th>Transaction ID / Teller No.</th>
                        <th>Transaction Date</th>
                        <th>Date Created</th>
                        <th>View Payment Evidence</th>
                        {hasPermission({
                          mod: "Preferences",
                          action: "VERIFY_PAYMENTS",
                        }) ? (
                          <React.Fragment>
                            <th>Verify Payment</th>
                            <th>Reject Payment</th>
                          </React.Fragment>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {item.user.first_name} {item.user.last_name}{" "}
                                {item.user.other_name}{" "}
                              </td>
                              <td>
                                {parseFloat(item["amount"])
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </td>
                              <td>{this.getType(item.type)}</td>
                              <td>{item.years}</td>
                              <td>{item.channel}</td>
                              <td>{item.transaction_id}</td>
                              <td>{this.dateConv(item.paid_date)}</td>

                              <td>{this.dateConv(item.created_at)}</td>
                              <td>
                                <button
                                  className="btn btn-lg btn-clean btn-icon btn-icon-md"
                                  title="View evidence"
                                  onClick={(e) => {
                                    this.viewImage(e, item.id);
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </button>
                              </td>
                              {hasPermission({
                                mod: "Preferences",
                                action: "VERIFY_PAYMENTS",
                              }) ? (
                                <React.Fragment>
                                  <td>
                                    <button
                                      className="btn btn-default btn-sm btn-clean btn-icon btn-icon-md d-flex"
                                      title="authorize request"
                                      onClick={(e) => {
                                        this.acknowlegdeRenewal(e, item.id);
                                      }}
                                    >
                                      <i className="fa fa-user-edit mr-1 mt-1"></i>
                                      Verify
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-default btn-sm btn-clean btn-icon btn-icon-md d-flex"
                                      title="Reject request"
                                      onClick={(e) => {
                                        this.rejectRenewal(e, item.id);
                                      }}
                                    >
                                      <i className="fa fa-user-times mr-1 mt-1"></i>
                                      Reject
                                    </button>
                                  </td>
                                </React.Fragment>
                              ) : null}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    errors: state.users.errors,
    message: state.users.message,
    status: state.users.status,
    // camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPendingRenewal: (page, year, month, day) =>
      getPendingRenewal(dispatch, page, year, month, day),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    acknowlegdeRenewal: (id) => acknowlegdeRenewal(dispatch, id),
    rejectRenewal: (id) => rejectRenewal(dispatch, id),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PendingRenewal);
