import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import {
//   getAllCamps,
//   deleteCampAction
// } from "./../../../store/actions/preferencesActions";
import { getAllAuxilliaries } from "./../../../store/actions/usersActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";
import $ from "jquery";

class ViewAuxilliaries extends Component {
  state = {
    auxilliaries: null,
    initdep: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ initdep: true });
    const { setPermissionsErrors, getAllAuxilliaries } = this.props;
    if (
        !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
        !hasPermission({ mod: "Preferences", action: "VIEW_MEMBER" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllAuxilliaries();
    }
  }
  initDependency = () => {
    if (this.state.initdep) {
      const old_element = document.querySelector(
          "script[src='/js/content.js']"
      );
      const new_element = document.createElement("script");
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }
      this.setState({ initdep: false });
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auxilliaries !== prevState.auxilliaries) {
      return {
        auxilliaries: nextProps.auxilliaries,
      };
    }
    return null;
  }
  getMemberStatus = (status) => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "DELISTED") {
      return "DELISTED";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "";
  };
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="text-uppercase">Membership</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active">View Auxilliaries</li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            {/* Default box */}
            <div className="card">
              <div className="card-header">
                <h3 className="card-title font-weight-bold">View Auxilliaries</h3>
                <div className="card-tools">
                  <Link to="/membership/auxilliaries/create">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-plus-circle mr-1"></i>
                      ADD NEW Auxilliary
                    </button>
                  </Link>
                </div>
              </div>
              <div className="card-body">
                {message && status ? (
                    <Message message={message} status={status} />
                ) : null}
                {spinner ? (
                    <div style={{ paddingBottom: "300px" }}>
                      <Spinner />
                    </div>
                ) : (
                    <div key="uniqueKey">
                      <table
                          id="example"
                          className="table table-hover table-bordered table-striped"
                          style={{
                            width: "100%",
                          }}
                      >
                        <thead>
                        <tr>
                          <th>S/N</th>

                          <th>Last Name</th>
                          <th>First Name</th>
                          <th>Other Name</th>
                          <th>Member Id</th>
                          <th>Spouse Id</th>
                          <th>Spouse Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Recruitment Method</th>
                          <th>Camp</th>
                          <th>Area</th>
                          <th>Region</th>
                          <th>State</th>

                          <th>Status</th>
                          <th>Due Date</th>

                          <th className="text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.auxilliaries &&
                        this.state.auxilliaries.map((auxilliary, index) => {
                          return (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{auxilliary.last_name}</td>
                                <td>{auxilliary.first_name}</td>
                                <td>{auxilliary.other_name || "--"}</td>
                                <td>{auxilliary.login_id || "--"}</td>
                                <td>
                                  {auxilliary.gideon
                                      ? auxilliary.gideon.login_id
                                      : "--"}
                                </td>
                                <td>
                                  {auxilliary.gideon
                                      ? `${auxilliary.gideon.first_name} ${auxilliary.gideon.last_name}`
                                      : "--"}
                                </td>
                                <td>
                                  {
                                  (auxilliary.phone != null)
                                    ? auxilliary.phone.trim()
                                    : '--'
                                  }
                                </td>
                                <td>{auxilliary.email || "--"}</td>
                                <td>
                                  {
                                    (auxilliary.recruitment != null && auxilliary.recruitment !="null")
                                        ? auxilliary.recruitment.trim()
                                        : '--'
                                  }
                                </td>
                                <td>
                                  {
                                    (auxilliary.camp != null && auxilliary.camp !="null")
                                        ? (auxilliary.camp.name != null) ? auxilliary.camp.name : '--'
                                        : '--'
                                  }
                                </td>
                                <td>
                                  {
                                    (auxilliary.camp != null && auxilliary.camp !="null")
                                        ? (auxilliary.camp.area != null)
                                            ? (auxilliary.camp.area.name != null)
                                              ? auxilliary.camp.area.name
                                              : '--'
                                            : '--'
                                        : '--'
                                  }
                                </td>
                                <td>
                                  {
                                    (auxilliary.camp != null && auxilliary.camp !="null")
                                        ? (auxilliary.camp.area != null)
                                            ? (auxilliary.camp.area.region != null)
                                              ? (auxilliary.camp.area.region.name != null)
                                                ? auxilliary.camp.area.region.name
                                                : '--'
                                              : '--'
                                            : '--'
                                        : '--'
                                  }
                                </td>
                                <td>
                                  {
                                    (auxilliary.camp != null && auxilliary.camp !="null")
                                        ? (auxilliary.camp.area != null)
                                            ? (auxilliary.camp.area.region != null)
                                              ? (auxilliary.camp.area.region.state.name != null)
                                                ? auxilliary.camp.area.region.state.name
                                                : '--'
                                              : '--'
                                            : '--'
                                        : '--'
                                  }
                                </td>
                                <td>
                                  {" "}
                                  {this.getMemberStatus(auxilliary.status)}{" "}
                                </td>
                                <td> {this.dateConv(auxilliary.expiry_date)} </td>
                                

                                <td>
                                <span
                                    style={{
                                      overflow: "visible",
                                      position: "relative",
                                      width: "110px",
                                      display: "flex"
                                    }}
                                >
                                <center>
                                  <Link
                                      to={`/membership/auxilliaries/edit/${auxilliary.id}`}
                                      className="btn btn-sm btn-success btn-icon mr-1"
                                      title="Edit Auxilliary"
                                  >
                                    <i className="fa fa-edit text-white"></i>
                                  </Link>

                                  <Link
                                      to={`/membership/auxilliaries/${auxilliary.id}`}
                                      className="btn btn-sm btn-info btn-icon mr-1"
                                      title="View More Details"
                                  >
                                    <i className="fa fa-eye text-white"></i>
                                  </Link>

                                </center>

                                </span>
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </div>
                )}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </section>
          {/* /.content */}


        <div className="modal fade" id="RemarkGidModal" tabIndex="-1" role="dialog" aria-labelledby="RemarkGidModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="RemarkGidModalLabel">Delist Member</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

              <label htmlFor="member_name">Member</label>
              <input type="text" id="member_name" className="form-control" defaultValue={this.state.name} placeholder="Member Name" />

              <br />

              <label htmlFor="remark">Remark</label>
              <textarea rows={3} id="remark" name="remark" 
                  value={this.state.remark}
                  onChange={this.handleChange} 
                  className="form-control" 
                  placeholder="Provide reasons for delisting member">{this.state.remark}</textarea>

                <span className="text-danger">{(this.state.error == 1) ? 'Error! Kindly fill the remark field' : ''}</span>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-danger" id="closeModal" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={() => this.triggerDelist()}>Approve</button>
              </div>
            </div>
          </div>
        </div>


        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auxilliaries: state.users.auxilliaries,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllAuxilliaries: () => dispatch(getAllAuxilliaries()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAuxilliaries);
