import { BASE_URL, token, storage_type } from "../../config";
import axios from "axios";

export const resetUsersState = (dispatch) => {
  dispatch({ type: "CLEAR_USERS_ERRORS" });
};
export const ClearUserReport = (dispatch) => {
  dispatch({ type: "CLEAR_USERS_REPORT" });
};
export const setConvenReport = (dispatch, payload) => {
  // console.log(payload)
  dispatch({ type: "SET_MEM_CONVEN_REPORT", payload: payload });
};
export const errorCreateKit = (dispatch) => {
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "ERRORS_CREATE_KIT_M" });
};
export const getAllUsers = () => {
  return (dispatch, getState) => {
    let users = getState().users.users;
    if (users.length) {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          users,
        },
      };

      dispatch({ type: "GET_ALL_USERS", resMessage });
    } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/users`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "GET_ALL_USERS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          // console.log(err.response);
          const resMessage = err.response.data;

          dispatch({ type: "UNLOAD_USERS_SPINNER" });
          dispatch({ type: "ERRORS_GET_ALL_USERS", resMessage });
        });
    }
  };
};
export const getAllUserCount = () => {
  return (dispatch, getState) => {
    let usersCount = getState().users.usersCount;
    if (usersCount) {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          count: usersCount,
        },
      };

      dispatch({ type: "GET_ALL_USERS_COUNT", resMessage });
    } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/members/count`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "GET_ALL_USERS_COUNT", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          // console.log(err.response);
          const resMessage = err.response.data;

          dispatch({ type: "UNLOAD_USERS_SPINNER" });
          dispatch({ type: "ERRORS_GET_ALL_USERS", resMessage });
        });
    }
  };
};

export const getAllMembers = () => {
  return (dispatch, getState) => {
    let members = getState().users.members;
    if (members.length) {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          members,
        },
      };

      dispatch({ type: "GET_ALL_MEMBERS", resMessage });
    } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: BASE_URL+`/members`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "GET_ALL_MEMBERS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          // console.log(err.response);
          const resMessage = err.response.data;

          dispatch({ type: "UNLOAD_USERS_SPINNER" });
          dispatch({ type: "ERRORS_GET_ALL_MEMBERS", resMessage });
        });
    }
  };
};

export const getAllStaffs = () => {
  return (dispatch, getState) => {
    let staffs = getState().users.staffs_1;
    if (staffs.length) {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          staffs,
        },
      };

      dispatch({ type: "GET_ALL_STAFFS", resMessage });
    } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/staffs`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "GET_ALL_STAFFS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          // console.log(err.response);
          const resMessage = err.response.data;

          dispatch({ type: "UNLOAD_USERS_SPINNER" });
          dispatch({ type: "ERRORS_GET_ALL_STAFFS", resMessage });
        });
    }
  };
};
export const getAllMembers_camp = () => {
  return (dispatch, getState) => {
    let members = getState().users.camp_members;
    if (members.length) {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          members,
        },
      };

      dispatch({ type: "GET_ALL_MEMBERS_CAMP", resMessage });
    } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/members/camp`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "GET_ALL_MEMBERS_CAMP", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          // console.log(err.response);
          const resMessage = err.response.data;

          dispatch({ type: "UNLOAD_USERS_SPINNER" });
          dispatch({ type: "ERRORS_GET_ALL_MEMBERS_CAMP", resMessage });
        });
    }
  };
};
export const getAllMembers_2 = async (dispatch, id) => {
  let result = {};
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/members`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "GET_ALL_MEMBERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result.members = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_GET_ALL_MEMBERS", resMessage });
      result.members = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/transfers/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result.details = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_TRANSFER_REQUEST_DET", resMessage });
      result.details = resMessage;
    });
  return result;
};

//Get all delisted members with status DELISTED
export const getAllDelisted = () => {
  return (dispatch, getState) => {
    let members = getState().users.delisted;
    // if (members.length) {
    //   dispatch({ type: "CLEAR_USERS_ERRORS" });
    //   const resMessage = {
    //     status: "success",
    //     message: "",
    //     payload: {
    //       members,
    //     },
    //   };

    //   dispatch({ type: "SET_ALL_DELISTED", resMessage });
    // } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/members/delisted`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_DELISTED", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_DELISTED", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        });
    // }
  };
};

//Get all delisted members history from user_status_history
export const getAllDelistedHistory = () => {
  return (dispatch, getState) => {
    let delistedhistory = getState().users.delistedhistory;
    // if (delistedhistory.length) {
    //   dispatch({ type: "CLEAR_USERS_ERRORS" });
    //   const resMessage = {
    //     status: "success",
    //     message: "",
    //     payload: {
    //       delistedhistory,
    //     },
    //   };

    //   dispatch({ type: "SET_ALL_DELISTED_HISTORY", resMessage });
    // } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/members/delist/history`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_DELISTED_HISTORY", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_DELISTED_HISTORY", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        });
    // }
  };
};

// Restor Delisted Members
export const restoreDelisted = async (dispatch, member, remark) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/members/${member}/delist`,
    headers: headers,
    data: {member: member, remark: remark},
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "RESTORE_DELIST_MEMBER", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      console.log(err);
      const resMessage = err.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_RESTORE_MEMBER", resMessage });
      result = resMessage;
    });
  return result;
};
// Delist Gideon Members
export const delistGidMember = async (dispatch, member, remark) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/members/${member}/delist`,
    headers: headers,
    data: {member: member, remark: remark},
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "DELIST_GID_MEMBER", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_DELIST_GID_MEMBER", resMessage });
      result = resMessage;
    });
  return result;
};

// Delist Auxilliary Members
export const delistAuxMember = async (dispatch, member, remark) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/members/${member}/delist`,
    headers: headers,
    data: {member: member, remark: remark},
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "DELIST_AUX_MEMBER", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_DELIST_AUX_MEMBER", resMessage });
      result = resMessage;
    });
  return result;
};

// create users here refers to create gideon
export const createUser = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/gideons`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_USERS_GIDS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      console.log(err);
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_USERS", resMessage });
      result = resMessage;
    });
  return result;
};
// update users here refers to update gideon
export const updateUser = async (dispatch, creds) => {
  if (creds.type === "G") {
    const fd = new FormData();
    // console.log(creds);
    Object.entries(creds).forEach(([key, value]) => {
      if (
        (key === "passed_on" && value === null) ||
        (key === "passed_on" && value === "null") ||
        (key === "previous_date" && value === null) ||
        (key === "previous_date" && value === "null") ||
        (key === "endorse_date" && value === null) ||
        (key === "endorse_date" && value === "null") ||
        (key === "accepted_date" && value === null) ||
        (key === "accepted_date" && value === "null") ||
        (key === "birth_date" && value === null) ||
        (key === "birth_date" && value === "null") ||
        (key === "last_payment_date" && value === null) ||
        (key === "last_payment_date" && value === "null")
      ) {
        fd.append(key, "");
      } else {
        fd.append(key, value);
      }
        // console.log(key, value)
      
    });
    // for (var pair of fd.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    // return;
    let result;
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "LOAD_USERS_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    await axios({
      method: "POST",
      url:
        creds.userLoginType === "S"
          ? `${BASE_URL}/gideons/updatebystaff/${creds.id}`
          : `${BASE_URL}/gideons/${creds.id}`, //modified/Added by Macdonald
      headers: headers,
      data: fd,
    })
      .then((res) => {
        const resMessage = res.data;
        // console.log(resMessage);
        dispatch({ type: "UPDATE_USERS_GID", resMessage });
        dispatch({ type: "UNLOAD_USERS_SPINNER" });
        result = resMessage;
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_USERS_SPINNER" });
        dispatch({ type: "ERRORS_UPDATE_USERS_GID", resMessage });
        result = resMessage;
      });
    return result;
  } else if (creds.type === "A") {
    const fd = new FormData();
    // console.log(creds);
    Object.entries(creds).forEach(([key, value]) => {
      if (
        (key === "passed_on" && value === null) ||
        (key === "passed_on" && value === "null") ||
        (key === "previous_date" && value === null) ||
        (key === "previous_date" && value === "null") ||
        (key === "endorse_date" && value === null) ||
        (key === "endorse_date" && value === "null") ||
        (key === "accepted_date" && value === null) ||
        (key === "accepted_date" && value === "null") ||
        (key === "birth_date" && value === null) ||
        (key === "birth_date" && value === "null") ||
        (key === "last_payment_date" && value === null) ||
        (key === "last_payment_date" && value === "null")
      ) {
        fd.append(key, "");
      } else {
        fd.append(key, value);
      }
      // console.log(key, value)
    });
    // for (var pair of fd.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    // return;
    let result;
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "LOAD_USERS_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    await axios({
      method: "POST",
      url:
        creds.userLoginType === "S"
          ? `${BASE_URL}/auxilliaries/auxupdatebystaff/${creds.id}`
          : `${BASE_URL}/auxilliaries/${creds.id}`, //Modified/Added by Macdonald
      headers: headers,
      data: fd,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "UPDATE_USERS_AUX", resMessage });
        dispatch({ type: "UNLOAD_USERS_SPINNER" });
        result = resMessage;
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_USERS_SPINNER" });
        dispatch({ type: "ERRORS_UPDATE_USERS_AUX", resMessage });
        result = resMessage;
      });
    return result;
  }
};
export const updateAuxilliary = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    if (
      (key === "passed_on" && value === null) ||
      (key === "passed_on" && value === "null") ||
      (key === "previous_date" && value === null) ||
      (key === "previous_date" && value === "null") ||
      (key === "endorse_date" && value === null) ||
      (key === "endorse_date" && value === "null") ||
      (key === "accepted_date" && value === null) ||
      (key === "accepted_date" && value === "null") ||
      (key === "birth_date" && value === null) ||
      (key === "birth_date" && value === "null") ||
      (key === "last_payment_date" && value === null) ||
      (key === "last_payment_date" && value === "null")
    ) {
      fd.append(key, "");
    } else {
      fd.append(key, value);
    }
    // console.log(key, value)
  });
  // for (var pair of fd.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/auxilliaries/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;

      console.log("Backend Response :");
      console.log(resMessage);

      dispatch({ type: "UPDATE_USERS_AUX", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_USERS_AUX", resMessage });
      result = resMessage;
    });
  return result;
  window.location.reload();
};
export const updateStaff = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    if (
      (key === "birth_date" && value === null) ||
      (key === "birth_date" && value === "null")
    ) {
      fd.append(key, "");
    } else {
      fd.append(key, value);
    }
    // console.log(key, value)
  });
  // for (var pair of fd.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/staffs/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_USERS_STA", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_USERS_STA", resMessage });
      result = resMessage;
    });
  return result;
};
export const updateFriend = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    if (
      (key === "birth_date" && value === null) ||
      (key === "birth_date" && value === "null")
    ) {
      fd.append(key, "");
    } else {
      fd.append(key, value);
    }
    // console.log(key, value)
  });
  // for (var pair of fd.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/friends/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_USERS_FRI", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_USERS_FRI", resMessage });
      result = resMessage;
    });
  return result;
};

export const getAllGideons = () => {
  return (dispatch, getState) => {
    let members = getState().users.gideons;
    // if (members.length) {
    //   dispatch({ type: "CLEAR_USERS_ERRORS" });
    //   const resMessage = {
    //     status: "success",
    //     message: "",
    //     payload: {
    //       members,
    //     },
    //   };

    //   dispatch({ type: "SET_ALL_GIDEONS", resMessage });
    // } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/gideons`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_GIDEONS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_GIDEONS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        });
    // }
  };
};
export const getAllAuxilliaries = () => {
  return (dispatch, getState) => {
    let members = getState().users.auxilliaries;
    // if (members.length) {
    //   dispatch({ type: "CLEAR_USERS_ERRORS" });
    //   const resMessage = {
    //     status: "success",
    //     message: "",
    //     payload: {
    //       members,
    //     },
    //   };

    //   dispatch({ type: "SET_ALL_AUXILLIARIES", resMessage });
    // } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/auxilliaries`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_AUXILLIARIES", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_AUXILLIARIES", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        });
    // }
  };
};
export const getAllStaff = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "LOAD_USERS_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/staffs`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_STAFFS", resMessage });
        dispatch({ type: "UNLOAD_USERS_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_STAFFS", resMessage });
        dispatch({ type: "UNLOAD_USERS_SPINNER" });
      });
  };
};
export const getAllFriends = () => {
  return (dispatch, getState) => {
    let friends = getState().users.friends;
    if (friends.length) {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          friends,
        },
      };

      dispatch({ type: "SET_ALL_FRIENDS", resMessage });
    } else {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "LOAD_USERS_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/friends`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_FRIENDS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_FRIENDS", resMessage });
          dispatch({ type: "UNLOAD_USERS_SPINNER" });
        });
    }
  };
};

//Modification/Addition by Macdonald
export const getLoginUserType = async (dispatch, criteria) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/gideons/get-usertype?id=${criteria}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_MEMBER", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const getOneMember = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/members/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_MEMBER", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const createKit = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/kits`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_KITS", resMessage });
      result = resMessage;
      // console.log(result);
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const createAuxillary = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/auxilliaries`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_AUXILLARY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_AUXILLARY", resMessage });
      result = resMessage;
    });
  return result;
};
export const createStaff = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/staffs`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_STAFF", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_STAFF", resMessage });
      result = resMessage;
    });
  return result;
};
export const createRelocationRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/relocations`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_RELOCATION_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_RELOCATION_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const payContribution = async (dispatch, creds) => {
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/contributions`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "PAY_CONTRIBUTION", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_PAY_CONTRIBUTION", resMessage });
      result = resMessage;
    });
  return result;
};
// export const payContributionHistory = async (dispatch) => {
//   let result;
//   dispatch({ type: "CLEAR_USERS_ERRORS" });
//   dispatch({ type: "LOAD_USERS_SPINNER" });
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${storage_type.getItem(token)}`,
//   };
//   await axios({
//     method: "GET",
//     url: `${BASE_URL}/contributions`,
//     headers: headers,
//   })
//     .then((res) => {
//       const resMessage = res.data;
//       dispatch({ type: "PAY_CONTRIBUTION", resMessage });
//       dispatch({ type: "UNLOAD_USERS_SPINNER" });
//       result = resMessage;
//     })
//     .catch((err) => {
//       const resMessage = err.response.data;
//       dispatch({ type: "UNLOAD_USERS_SPINNER" });
//       dispatch({ type: "ERRORS_PAY_CONTRIBUTION", resMessage });
//       result = resMessage;
//     });
//   return result;
// };
export const memberRenew = async (dispatch, creds) => {
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });

  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/renewals`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "PAY_RENEWAL", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_PAY_RENEWAL", resMessage });
      result = resMessage;
    });
  return result;
};
export const createFriends = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/friends`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_FRIENDS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_FRIENDS", resMessage });
      result = resMessage;
    });
  return result;
};

export const getPendingKits = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/kits/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/relocations/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_SET_PENDING_RELOCATION_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/relocations/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_SET_AUTHORIZED_RELOCATION_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getAuthorizedRequestCan = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/relocations/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_SET_AUTHORIZED_RELOCATION_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getPendingContribution = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/contributions/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingContributionNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getPendingContributionCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/payments/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getPendingContributionMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/reports/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingContributionLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/reports/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingContributionRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/reports/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getHistoryContribution = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/contributions?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getVerifiedContribution = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/contributions/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_VERIFIED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedContributionNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_VERIFIED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedContributionCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/payments/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_VERIFIED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedContributionMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/reports/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_VERIFIED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedContributionLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/reports/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_VERIFIED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedContributionRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/reports/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_VERIFIED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getRejectedContribution = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/contributions/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getRejectedContributionNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getRejectedContributionCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/payments/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getRejectedContributionMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/reports/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getRejectedContributionLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/reports/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getRejectedContributionRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/reports/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getPendingRenewal = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/renewals/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedRenewal = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/renewals/verified?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getConfirmedRenewal = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/renewals/confirmed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getRejectedRenewal = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/renewals/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_REJECTED_RENEWAL", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingKitsCamp = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/kits/camp/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCampActitivies = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCampActitiviesNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCampActitiviesRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCampActitiviesMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCampActitiviesCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCampActitiviesLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getAllPendingCampActivityReports = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_ACTIVITY_REPORT", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllRejectedCampActivityReports = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_ACTIVITY_REPORT", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllAuthorizedCampActivityReports = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_PENDING_ACTIVITY_REPORT", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getAllPendingCampActivityReports_camp = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports/camp/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_SET_PENDING_ACTIVITY_REPORT",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getAllRejectedCampActivityReports_camp = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports/camp/rejected?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_SET_PENDING_ACTIVITY_REPORT",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllAuthorizedCampActivityReports_camp = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports/camp/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_SET_PENDING_ACTIVITY_REPORT",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getPendingScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/requests/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingHqCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/camp/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/personal/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingMerchandiseRequest_trans = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/personal/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingMerchandiseRequest_trans_re = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/personal/returned?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingMerchandiseRequest_re = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/personal/returned?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingDepotCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/camp/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_DEPOT_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getReceivHqCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/camp/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivHqPersonalMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/personal/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivHqPersonalMerchandiseRequestTrans = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/personal/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getReceivDepotCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/camp/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedHqCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/camp/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedHqCampScriptureRequest_Merch = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/personal/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedHqCampScriptureRequest_Merch_Trans = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/personal/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedDepotCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/camp/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_PENDING_CAMP_DEPOT_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/requests/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getMyScriptureRequest = async (dispatch, page = "") => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/requests${page}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ALL_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingCampActivities = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/cancelled?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingCampActivitiesNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/cancelled?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingCampActivitiesRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/cancelled?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingCampActivitiesMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/cancelled?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAllCancelledCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/cancelled?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingCampActivitiesLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/cancelled?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedCampActivities = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/completed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedCampActivitiesNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/completed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedCampActivitiesRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/completed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedCampActivitiesMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/completed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedCampActivitiesCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/completed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedReportHqCampActivitiesCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/reports-hq?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedStateActivitiesCon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/reports?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getCompletedCampActivitiesLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/completed?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const ListgetCompletedCampActivitiesNon = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/completed?page=${page}&year=${year}&month=${month}&day=${day}&report=${true}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const ListgetCompletedCampActivitiesRec = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/completed?page=${page}&year=${year}&month=${month}&day=${day}&report=${true}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const ListgetCompletedCampActivitiesMet = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/completed?page=${page}&year=${year}&month=${month}&day=${day}&report=${true}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const ListgetCompletedCampActivitiesLeg = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/completed?page=${page}&year=${year}&month=${month}&day=${day}&report=${true}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_CANCELLED_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const cancelCampActivity = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/camps/activities/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const cancelCampActivityNon = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/camps/activities/non-scripture/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const cancelCampActivityRec = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/camps/activities/recruitment/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const cancelCampActivityMet = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/camps/activities/meetings/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const cancelCampActivityCon = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/conventions/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const cancelCampActivityLeg = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/camps/activities/bequest/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getDetailRequest = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      // dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getDetailReport = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/reports/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      // dispatch({ type: "DELETE_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_SCRIPTURE_REPORT", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const raiseScriptureRequisition = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/${creds.id}/requests`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "RAISE_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const generateReportOne = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/reports/${creds.report_id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "RAISE_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createReportOne = async (dispatch, creds) => {
  // alert("here");
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "RAISE_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createReportOneRec = async (dispatch, creds) => {
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment/reports/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "RAISE_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createReportOneMet = async (dispatch, creds) => {
  // alert("here");
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings/reports/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "RAISE_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createReportOneLeg = async (dispatch, creds) => {
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest/reports/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "RAISE_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const RejectActivityReport = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/camps/activities/reports/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_ACTIVITY_REPORT", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_ACTIVITY_REPORT", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authorizeActivityReport = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/reports/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "AUTHORIZE_ACTIVITY_REPORT", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_AUTHORIZE_ACTIVITY_REPORT", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const supplyScriptureRequisition = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "SUPPLY_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_RAISE_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const activityCompleted = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "COMPLETED_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_COMPLETED_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const activityCompletedRec = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "COMPLETED_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_COMPLETED_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const activityCompletedMet = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "COMPLETED_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_COMPLETED_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const activityCompletedCon = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "COMPLETED_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_COMPLETED_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const activityCompletedLeg = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "COMPLETED_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_COMPLETED_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const activityCompletedNon = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "COMPLETED_CAMP_ACTIVITY", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_COMPLETED_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingKitsMember = async (dispatch, page = "") => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/kits/member${page}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_MEMBER_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeKits = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/kits/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_KITS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeKitsMember = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/kits/member/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_KITS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/relocations/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_KITS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeRequestCan = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/relocations/${creds.relocationId}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_KITS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const acknowlegdeContribution = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/contributions/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeContributionNon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeContributionCon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/payments/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeContributionMet = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings/reports/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeContributionLeg = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest/reports/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeContributionRec = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment/reports/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const reverseContribution = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/contributions/revert/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const reverseRenewal = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/renewals/revert/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeHqMerchandise = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/merchandise/requests/${creds}/receive`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_MERCHANDISE_RECEPTION", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_ACKNOWLEGDE_MERCHANDISE_RECEPTION",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeHqMerchandiseTrans = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/merchandise/requests/${creds}/receive`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_MERCHANDISE_RECEPTION", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_ACKNOWLEGDE_MERCHANDISE_RECEPTION",
        resMessage,
      });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const rejectContribution = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/contributions/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const rejectContributionNon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const rejectContributionCon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/payments/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const rejectContributionMet = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings/reports/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const rejectContributionLeg = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest/reports/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const rejectContributionRec = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment/reports/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const acknowlegdeRenewal = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/renewals/verify/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const confirmRenewal = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/renewals/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getNotification = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  // dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/notifications`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "GET_NOTIFICATIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_NOTIFICATIONS", resMessage });
      // dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const removeNofication = async (dispatch, creds) => {
  // console.log(creds);
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  // dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/notifications/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "UNLOAD_USERS_SPINNER" });
      // dispatch({ type: "DELETE_NOTIFICATIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_DELETE_NOTIFICATIONS", resMessage });
      // dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const rejectRenewal = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/renewals/reject/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "REJECT_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_REJECT_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createNewActivity = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_NEW_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_NEW_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

//------------------------------------------------------------------
// MODIFICATIONS /ADDITION BY MACDONALD
export const reverseCampActivityNonScripture = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture/reports/revert/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const reverseVerifiedCon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/payments/revert/${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const reverseRecruitment = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment/reports/revert?id=${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const reverseContributionMet = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings/reports/revert?id=${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const reverseBequestContribution = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest/reports/revert?id=${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

//----------------------------------END-------------------------------------

export const createNewActivityNon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_NEW_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_NEW_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createNewActivityRec = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_NEW_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_NEW_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createNewActivityMet = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_NEW_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_NEW_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createNewActivityCon = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_NEW_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_NEW_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const createNewActivityLeg = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CREATE_NEW_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CREATE_NEW_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const getOneCampActivity = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};
export const getOneCampActivityNon = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/non-scripture/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};
export const getOneCampActivityRec = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/recruitment/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};
export const getOneCampActivityMet = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/meetings/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const getOneCampActivityLeg = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/activities/bequest/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const updateCampActivity = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const updateCampActivityRec = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/recruitment/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const updateCampActivityMet = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/meetings/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const updateNewActivityCon = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/conventions/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const registerActivityCon = async (dispatch, creds) => {
  const fd = new FormData();

  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/${creds.id}/register`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const submitReportActivityCon = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/${creds.id}/reports`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const updateCampActivityLeg = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/bequest/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const updateCampActivityNon = async (dispatch, creds) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/activities/non-scripture/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getInventoryActivity = async (dispatch) => {
  // alert("dfgdgd");
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camp/inventories`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      // dispatch({ type: "UPDATE_CAMP_ACTIVITIES", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_CAMP_ACTIVITIES", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const changePasswordRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/password/change`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "CHANGE_PASSWORD", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_CHANGE_PASSWORD", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const updateProfile = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/profile/update`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      let resMessage = res.data;

      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "UPDATE_PROFILE", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_UPDATE_PROFILE", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedKits = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/kits/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_RECEIVED_KITS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const cancelRemita = async (dispatch, rrr) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/orders/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CANCEL_STORE_REMITAL", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaRenewal = async (dispatch, rrr, member_id) => {
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/renewals/remita/cancel/${rrr}`,
    headers: headers,
    data: {member_id: member_id}
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaRenewalNon = async (dispatch, rrr) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/non-scripture/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaRenewalRec = async (dispatch, rrr) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/recruitment/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaRenewalMet = async (dispatch, rrr) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/meetings/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaRenewalLeg = async (dispatch, rrr) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps/activities/bequest/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaConvention = async (dispatch, rrr) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/conventions/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const cancelRemitaContribution = async (dispatch, rrr) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/contributions/remita/cancel/${rrr}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_CANCEL_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const verifyRemita = async (dispatch, transaction_id) => {
  // return;
  let result;
  dispatch({ type: "CLEAR_USERS_ERRORS" });
  dispatch({ type: "LOAD_USERS_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/remita/payment/verify`,
    headers: headers,
    data: { rrr: transaction_id },
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_USERS", resMessage });
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_VERIFY_REMITA", resMessage });
      result = resMessage;
    });
  return result;
};
export const showSuccessMessage = () => {
  const resMessage = {
    status: "success",
    message: "Transaction completed successfully",
  };
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "SET_SUCCESS_MESSAGE_REMITA", resMessage });
  };
};
