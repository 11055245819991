import React, { Component } from "react";
import { connect } from "react-redux";
import { resetAuthPermState } from "./../../../store/actions/authActions";
import {
  getAllCamps,
  getAllRegions,
  getAllAreas,
  getAllStates,
  getAllBibles,
} from "./../../../store/actions/preferencesActions";
import Message from "./../../helpers/Message";
import { storage_type, user_auth_details, dehash } from "./../../../config";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  getAllMembers_camp,
  getAllCampActitivies,
  getAllCampActitiviesNon,
  getAllCampActitiviesCon,
  getAllCampActitiviesRec,
  getAllCampActitiviesMet,
  getAllCampActitiviesLeg,
  getAllMembers,
  getAllDelisted,
  getAllStaffs,
  getAllUsers,
  getAllGideons,
  getAllAuxilliaries,
  getAllUserCount,
} from "../../../store/actions/usersActions";
// import "./../../../remita_inline";
// import $ from "jquery";

class Dashboard extends Component {
  state = {
    user_type: "G",
    user_status: null,
    camps: null,
    camp_members: null,
    members: null,
    delisted: null,
    staffs_1: null,
    users: null,
    usersCount: null,
    bibles: null,
    auxilliaries: null,
    gideons: null,
  };
  getMemberType = (type) => {
    this.setState({ user_type: type });
  };
  getNumberDay = (exp, lpd) => {
    const date1 = new Date(lpd);
    const date2 = new Date(exp);
    const date3 = new Date();
    const obj = {
      init: parseInt(date1.getTime() / (1000 * 3600 * 24)),
      final: parseInt(date2.getTime() / (1000 * 3600 * 24)),
      show: parseInt(date3.getTime() / (1000 * 3600 * 24)),
      per: parseInt(
        (parseInt(date3.getTime()) / parseInt(date2.getTime())) * 100
      ),
    };
    return obj;
  };
  getMemberStatus = (status) => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "DELISTED") {
      return "DELISTED";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "";
  };
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };

  componentDidMount() {
    // console.log(RmPaymentEngine);
    window.$('[data-toggle="popover"]').popover();

    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );

      this.getMemberType(user.type);
      this.setState(
        {
          ...user,
          user_status: this.getMemberStatus(user.status),
        },
        () => {
          if (this.state.type !== "S") {
            this.props.getAllMembers_camp();

            this.props.getAllCampActitivies().then((res) => {
              if (res.status === "success") {
                this.setState({ activity_total: res.payload.activities.total });
              }
            });

            this.props.getAllCampActitiviesNon().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_non: res.payload.activities.total,
                });
              }
            });
            this.props.getAllCampActitiviesCon().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_con: res.payload.conventions.total,
                });
              }
            });
            this.props.getAllCampActitiviesRec().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_rec: res.payload.activities.total,
                });
              }
            });
            this.props.getAllCampActitiviesMet().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_met: res.payload.activities.total,
                });
              }
            });
            this.props.getAllCampActitiviesLeg().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_leg: res.payload.activities.total,
                });
              }
            });
          }
          // this.props.getAllCamps();
          // this.props.getAllAreas();
          // this.props.getAllRegions();
          this.props.getAllStates();
          // this.props.getAllMembers();
          this.props.getAllUserCount();
          this.props.getAllDelisted();
          // this.props.getAllUsers();
          // this.props.getAllStaffs();
          // this.props.getAllAuxilliaries();
          // this.props.getAllGideons();
          // this.props.getAllBibles();

          this.setState({
            num_days: this.getNumberDay(
              this.state.expiry_date,
              this.state.last_payment_date
            ),
          });
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.camps !== prevState.camps ||
      nextProps.camp_members !== prevState.camp_members ||
      nextProps.members !== prevState.members ||
      nextProps.delisted !== prevState.delisted ||
      nextProps.usersCount !== prevState.usersCount ||
      nextProps.staffs_1 !== prevState.staffs_1
    ) {
      return {
        camps: nextProps.camps,
        camp_members: nextProps.camp_members,
        members: nextProps.members,
        delisted: nextProps.delisted,
        usersCount: nextProps.usersCount,
        staffs_1: nextProps.staffs_1,
      };
    }
    return null;
  }

  componentWillUnmount() {
    window.$('[data-toggle="popover"]').popover("hide");
    const { resetAuthPermState } = this.props;
    resetAuthPermState();
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    let progessbar = null;
    const { message_perm, status_perm } = this.props;
    const { user_type } = this.state;
    if (this.state.num_days) {
      if (this.state.status === "GOOD") {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      } else if (this.state.status === "DELI") {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      } else if (this.state.status === "DROP") {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      } else {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      }
    }
    if (user_type === "G" || user_type === "A") {
      return (
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {message_perm && status_perm ? (
            <Message message={message_perm} status={status_perm} />
          ) : null}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1> My Dashboard</h1>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            {/* Default box */}
            <div className="card mb-5">
              <div className="card-header">
                <h3 className="card-title">
                  <b>Camp No</b>
                  {this.state.camp ? (
                    <i>--{this.state.camp.camp_no}</i>
                  ) : (
                    ""
                  )}{" "}
                  &nbsp;
                  <b>Camp </b>
                  {this.state.camp ? <i>--{this.state.camp.name}</i> : ""}{" "}
                  &nbsp;
                  <b>Area</b>{" "}
                  {this.state.camp ? <i>--{this.state.camp.area.name}</i> : ""}{" "}
                  &nbsp;
                  <b>Region</b>{" "}
                  {this.state.camp ? (
                    <i>--{this.state.camp.area.region.name}</i>
                  ) : (
                    ""
                  )}{" "}
                  &nbsp;<b>State</b>{" "}
                  {this.state.camp ? (
                    <i>--{this.state.camp.area.region.state.name}</i>
                  ) : (
                    ""
                  )}
                </h3>
                <div className="card-tools"></div>
              </div>
              <div className="card-body">
                <div className="row d-flex justify-content-between">
                  <div>
                    <p className="lead">
                      Status:{" "}
                      <span className="font-italic">
                        {this.state.user_status}
                      </span>{" "}
                    </p>
                  </div>
                  <div>
                    <p className="lead">
                      Due Date:{" "}
                      <span className="font-italic">
                        {this.dateConv(this.state.expiry_date)}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                {progessbar}
              </div>
              {/* /.card-body */}
              <div className="card-footer text-center">
                <Link
                  to="/personal/payments/renewal"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold "
                >
                  Renew
                </Link>
              </div>
              {/* /.card-footer*/}
            </div>
            {/* /.card */}
            <div className="row mt-3">
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link
                  to="/personal/activities/pending"
                  className="small-box-footer"
                > */}
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>
                      {(this.state.activity_total
                        ? this.state.activity_total
                        : 0) +
                        (this.state.activity_total_non
                          ? this.state.activity_total_non
                          : 0) +
                        (this.state.activity_total_con
                          ? this.state.activity_total_con
                          : 0) +
                        (this.state.activity_total_rec
                          ? this.state.activity_total_rec
                          : 0) +
                        (this.state.activity_total_met
                          ? this.state.activity_total_met
                          : 0) +
                        (this.state.activity_total_leg
                          ? this.state.activity_total_leg
                          : 0)}
                    </h3>
                    <p> Activities </p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-globe" />
                  </div>

                  <button
                    className="small-box-footer btn-block border-0 stt "
                    data-toggle="popover"
                    data-placement="top"
                    title="Pending Activities"
                    data-content={`Scripture Distribution : ${
                      this.state.activity_total ? this.state.activity_total : 0
                    }, Camp Activities : ${
                      this.state.activity_total_non
                        ? this.state.activity_total_non
                        : 0
                    }, Recruitment Activity : ${
                      this.state.activity_total_rec
                        ? this.state.activity_total_rec
                        : 0
                    }, Camp Meetings : ${
                      this.state.activity_total_met
                        ? this.state.activity_total_met
                        : 0
                    }, Legacy/Bequest : ${
                      this.state.activity_total_leg
                        ? this.state.activity_total_leg
                        : 0
                    },Convention : ${
                      this.state.activity_total_con
                        ? this.state.activity_total_con
                        : 0
                    }  `}
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </button>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/personal/camps" className="small-box-footer"> */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{this.state.camps ? this.state.camps.length : 0}</h3>
                    <p>Camps</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-campground" />
                  </div>
                  <Link to="/personal/camps" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link
                  to="/personal/change-password"
                  className="small-box-footer"
                > */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>
                      {" "}
                      <i className="fas fa-lock-open" />
                    </h3>
                    <p>Change Password</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-lock" />
                  </div>
                  <Link
                    to="/personal/change-password"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/personal/camp-members" className="small-box-footer"> */}
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>
                      {this.state.camp_members
                        ? this.state.camp_members.length
                        : 0}
                    </h3>
                    <p>Camp Members</p>
                  </div>
                  <div className="icon">
                    {/* <i className="ion ion-contacts" /> */}
                    <i className="fas fa-users" />
                  </div>
                  <Link
                    to="/personal/camp-members"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
            </div>
          </section>
          {/* /.content */}
        </div>
      );
    } else if (user_type === "S") {
      return (
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {message_perm && status_perm ? (
            <Message message={message_perm} status={status_perm} />
          ) : null}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1> Staff Dashboard</h1>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            <div className="callout callout-info">
              {/* <h5><i class="fas fa-info"></i> Note:</h5> */}
              <p className="lead">
                Welcome back {this.state.last_name || " "}{" "}
                {this.state.first_name}, your last login was{" "}
                <i>
                  {this.state.last_login ? (
                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                      {this.state.last_login}
                    </Moment>
                  ) : null}
                </i>
              </p>
            </div>

            <div className="row mt-5">
              <div className="col-lg-3 col-6">
                {/* <Link to="/staffs" className="small-box-footer"> */}
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>
                      {this.state.staffs_1 ? this.state.staffs_1.length : 0}
                    </h3>
                    <p> Staffs</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-globe" />
                  </div>
                  <Link to="/staffs" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/personal/camps" className="small-box-footer"> */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{this.state.camps ? this.state.camps.length : 0}</h3>
                    <p>Camps</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-campground" />
                  </div>
                  <Link to="/personal/camps" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link
                  to="/personal/change-password"
                  className="small-box-footer"
                > */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>
                      {" "}
                      <i className="fas fa-lock-open" />
                    </h3>
                    <p>Change Password</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-lock" />
                  </div>
                  <Link
                    to="/personal/change-password"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{this.state.usersCount ? this.state.usersCount : 0}</h3>
                    <p> Members</p>
                  </div>
                  <div className="icon">
                    {/* <i className="ion ion-contacts" /> */}
                    <i className="fas fa-users" />
                  </div>
                  <Link to="/members" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-primary">
                  <div className="inner">
                    <h3>{this.state.delisted ? this.state.delisted.length : 0}</h3>
                    <p> Delisted</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-trash" />
                  </div>
                  <Link to="/membership/delist" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
            </div>
          </section>
          {/* /.content */}
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    message_perm: state.auth.message_perm,
    status_perm: state.auth.status_perm,
    camps: state.preferences.camps,
    camp_members: state.users.camp_members,
    members: state.users.members,
    delisted: state.users.delisted,
    users: state.users.users,
    usersCount: state.users.usersCount,
    staffs_1: state.users.staffs_1,
    bibles: state.preferences.bibles,
    gideons: state.users.gideons,
    auxilliaries: state.users.auxilliaries,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCamps: () => dispatch(getAllCamps()),
    getAllAreas: () => dispatch(getAllAreas()),
    getAllRegions: () => dispatch(getAllRegions()),
    getAllStates: () => dispatch(getAllStates()),
    getAllMembers: () => dispatch(getAllMembers()),
    getAllDelisted: () => dispatch(getAllDelisted()),
    getAllMembers_camp: () => dispatch(getAllMembers_camp()),
    resetAuthPermState: () => dispatch(resetAuthPermState()),
    getAllCampActitivies: (page) => getAllCampActitivies(dispatch, page),
    getAllCampActitiviesNon: (page) => getAllCampActitiviesNon(dispatch, page),
    getAllCampActitiviesCon: (page) => getAllCampActitiviesCon(dispatch, page),
    getAllCampActitiviesRec: (page) => getAllCampActitiviesRec(dispatch, page),
    getAllCampActitiviesMet: (page) => getAllCampActitiviesMet(dispatch, page),
    getAllCampActitiviesLeg: (page) => getAllCampActitiviesLeg(dispatch, page),
    getAllStaffs: () => dispatch(getAllStaffs()),
    getAllUsers: () => dispatch(getAllUsers()),
    getAllUserCount: () => dispatch(getAllUserCount()),
    getAllBibles: () => dispatch(getAllBibles()),
    getAllAuxilliaries: () => dispatch(getAllAuxilliaries()),
    getAllGideons: () => dispatch(getAllGideons()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
