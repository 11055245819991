export const auth_data = "pntnYA7DqO4G0mwz3QYrpqKQ4SujuvydlAut7t89";

export const token = "pntnYA7DqO4G0mwz3QYrpqKQ4SujuvydlAut7t89";
export const user_auth_details = "NlHWoY0d0lxPm87gh2NVLJv25Zrx9qbKLrfPpVGZFrtkpxTU1dEp2pvaXXZM";
export const user_permission_details = "OHgI3PW1Qd3qT473DyCJT1ms2O2iAkz0xIMW80G7jIf3vAcl6nhfgbWLqHHX";
export const system_all_permission = "E3jLQix5gamDWFnbZzwHFcbzIpxFuHVMEUFSr59H7bc1wJ1XwVy1l7ufbJGu";
export const local_cart = "E3jLQix5gamDWNlHWoY0d0lxPm873QYrpqKQ4Sujuvyd";

export const once_login = "5zPSoEFdiuwF2VD0XooS4HZpERL60829tzS1WjzUZP7e93FtRn"; // temporary solution for dropdown issues
export const storage_type = window.localStorage;

export const image_path = process.env.REACT_APP_image_path;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
//
export const depotnamelist = [
  {
    name: "",
  },
];

export const depotrowlist = [
  {
    name: "",
  },
];

export const UserIdList = [];

export var NameList = "";

export const SelectedDepot = "";

const secret = "McvJcHgaqPQROblWRkzDoZ8Np8wKjD1sL09CRrd0T8IwxeeOeIUTKzc1sjQh";
const cipher = (salt) => {
  let textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  let byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  let applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) =>
    text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};
export const hash = cipher(secret);

const decipher = (salt) => {
  let textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  // eslint-disable-next-line
  let saltChars = textToChars(salt);
  let applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};
export const dehash = decipher(secret);
