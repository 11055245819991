import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllRoles,
  deleteRoleAction
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import RoleRow from "./RoleRow";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
// import "bootstrap/dist/js/bootstrap.js";
// import bootbox from "bootbox";

class ViewRoles extends Component {
  state = {
    roles: null
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllRoles } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_ROLE" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllRoles();
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.roles !== prevState.roles) {
      return {
        roles: nextProps.roles
      };
    }
    return null;
  }
  deleteRole = (e, id) => {
    const { deleteRoleAction } = this.props;
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this role?")) {
      deleteRoleAction(id);
    }
    // bootbox.confirm("Are you sure you want to delete this role?", function(
    //   result
    // ) {
    //   if (result) {
    //     deleteRoleAction(id);
    //   }
    // });
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFRENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Roles</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Roles</h3>
              <div className="card-tools">
                <Link to="/preferences/roles-and-permissions/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle mr-1"></i>
                    ADD NEW ROLE
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="role_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Role</th>
                        <th>No of Members</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.roles &&
                        this.state.roles.map(role => (
                          <RoleRow
                            key={role.id}
                            deleteRole={this.deleteRole}
                            role={role}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    roles: state.preferences.roles,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllRoles: () => dispatch(getAllRoles()),
    deleteRoleAction: id => dispatch(deleteRoleAction(id)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewRoles);
