import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllCamps,
  deleteCampAction,
  resetPreferencesState
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class ViewCamps extends Component {
  state = {
    camps: null
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllCamps } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_CAMP" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllCamps();
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.camps !== prevState.camps) {
      return {
        camps: nextProps.camps
      };
    }
    return null;
  }
  deleteCamp = (e, id) => {
    const { deleteCampAction } = this.props;
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this camp?")) {
      deleteCampAction(id);
    }
  };
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Camps</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Camps</h3>
              <div className="card-tools">
                <Link to="/preferences/camps/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle mr-1"></i>
                    ADD NEW CAMP
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="camp_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Camp</th>
                        <th>Camp Number</th>
                        <th>Address</th>
                        <th>No of Member(s)</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.camps &&
                        this.state.camps.map((camp, index) => {
                          return (
                            <tr key={index}>
                              <td>{camp.name}</td>
                              <td>{camp.camp_no}</td>
                              <td>{camp.address}</td>
                              <td>{camp.users_count}</td>
                              <td>{camp.area.name}</td>
                              <td>{camp.area.region.name}</td>
                              <td>{camp.area.region.state.name}</td>
                              <td>
                                <span
                                  style={{
                                    overflow: "visible",
                                    position: "relative",
                                    width: "110px"
                                  }}
                                >
                                  <Link
                                    to={`/preferences/camps/edit/${camp.id}`}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="Edit Camp"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>

                                  <Link
                                    to="#!"
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="Close Camp"
                                    onClick={e => {
                                      this.deleteCamp(e, camp.id);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link>

                                  <Link
                                    to={`/preferences/camps/${camp.id}`}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="View More Details"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    camps: state.preferences.camps,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllCamps: () => dispatch(getAllCamps()),
    resetPreferencesState: () => resetPreferencesState(dispatch),
    deleteCampAction: id => dispatch(deleteCampAction(id)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCamps);
