import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  user_auth_details,
  dehash,
  storage_type,
  image_path
} from "../../../../config";
import PrefrencesAside from "./PrefrencesAside";
import MembershipAside from "./MembershipAside";
import CampAside from "./CampAside";
import PersonalAside from "./PersonalAside";
import ReportAside from "./ReportAside";
import $ from "jquery";

class Aside extends Component {
  state = {
    first_name: null,
    last_name: null,
    user_image: null
  };
  componentDidMount() {
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      // console.log(user);
      this.setState({
        first_name: user.first_name,
        last_name: user.last_name,
        user_image: user.image
      });
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    // console.log("props", nextProps, "state", nextState);
    return nextState !== this.state;
  }
  handleActiveLink = e => {
    // e.preventDefault();
    e.stopPropagation();
    // console.log(e.target.tagName);
    const allLinks = $(".nav-link");
    Array.from(allLinks).forEach(link => {
      link.classList.remove("active");
    });
    if (e.target.tagName === "A") {
      $(e.target).addClass("active");
    } else if (e.target.tagName === "I") {
      $(e.target)
        .parent()
        .addClass("active");
    } else if (e.target.tagName === "P") {
      $(e.target)
        .parent()
        .addClass("active");
    }
  };

  render() {
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/" className="brand-link">
          <img
            src="/dist/img/logo-10.png"
            alt="TGIN Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">TGIN</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                style={{
                  width: "36px",
                  height: "36px"
                }}
                src={
                  this.state.user_image
                    ? `${image_path}${this.state.user_image}`
                    : "/dist/img/ava.png"
                }
                className="img-circle elevation-2"
                alt="User"
              />
            </div>
            <div className="info">
              <a href="#!" className="d-block">
                {this.state.last_name} {this.state.first_name}
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <MembershipAside handleActiveLink={this.handleActiveLink} />
              <CampAside handleActiveLink={this.handleActiveLink} />
              <PersonalAside handleActiveLink={this.handleActiveLink} />
              <ReportAside handleActiveLink={this.handleActiveLink} />
              <PrefrencesAside handleActiveLink={this.handleActiveLink} />
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    );
  }
}

export default Aside;
