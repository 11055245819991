import authReducer from "./authReducer";
import preferencesReducer from "./preferencesReducer";
import usersReducer from "./usersReducer";
import tstoreReducer from "./tstoreReducer";
import reportReducer from "./reportReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: authReducer,
  preferences: preferencesReducer,
  users: usersReducer,
  tstore: tstoreReducer,
  reports: reportReducer
});

export default rootReducer;
