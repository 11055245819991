import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";

class MembershipAside extends Component {
  render() {
    const { handleActiveLink } = this.props;
    return (
      <React.Fragment>
        {/* start view membership moudle */}
        {hasPermission({
          mod: "Preferences",
          action: "VIEW_MEMBERSHIP",
        }) ? (
          <li className="nav-item has-treeview">
            <a href="#!" className="nav-link">
              <i className="nav-icon fas fa-chart-pie" />
              <p>
                MEMBERSHIP
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_MEMBER",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_MEMBER",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_MEMBER",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Gideon
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_MEMBER",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/gideons/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Gideon Record</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_MEMBER",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "VIEW_MEMBER",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/gideons"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Gideon Record</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "DELIST_MEMBER",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/delist/gideons"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Delist Gideon Member</p>
                        </Link>
                      </li>
                    ) : null}

                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "CREATE_MEMBER",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_MEMBER",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_MEMBER",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Auxilliary
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_MEMBER",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/auxilliaries/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create an Auxilliary Record</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_MEMBER",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "VIEW_MEMBER",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/auxilliaries"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View an Auxilliary Record</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "DELIST_MEMBER",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/delist/auxilliaries"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Delist Auxilliary Member</p>
                        </Link>
                      </li>
                    ) : null}

                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "CREATE_STAFF",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_STAFF",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_STAFF",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Staff
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_STAFF",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/staff/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create a Staff Record</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_STAFF",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "VIEW_STAFF",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/staffs"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View a Staff Record</p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "CREATE_FRIENDS",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_FRIENDS",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_FRIENDS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Friends
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_FRIENDS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/friends/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create a Friend Record</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_FRIENDS",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "VIEW_FRIENDS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/friends"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View a Friend Record</p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "CREATE_KIT",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_KIT_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Member Kit
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_KIT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/membership/kit/new"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> New</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_KIT_HQ",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/kit/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/kit/received"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Delivered</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_RELOCATION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Member Movement
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_RELOCATION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/relocation-request/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/relocation-request/authorized"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Authorized</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/relocation-request/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected </p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VERIFY_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Member Retention
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/renewal/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/renewal/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                    {hasPermission({
                      mod: "Preferences",
                      action: "MEMBER_RETENTION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/renewal/verify"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/renewal/confirmed"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Confirmed</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Member Contribution
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/contribution/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/contribution/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/contribution/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/remita/verify"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verify Payment</p>
                          </Link>
                        </li>

                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Verify Convention
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/convention-activities/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/convention-activities/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/convention-activities/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Verify Camp Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/camp-activities/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/camp-activities/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/camp-activities/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Verify Bequest Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/bequest-activities/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/bequest-activities/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/bequest-activities/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Verify Recruitment Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/recruitment-activities/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/recruitment-activities/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/recruitment-activities/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "VIEW_PAYMENTS",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Verify Meetings Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_PAYMENTS",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/meetings-activities/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/meetings-activities/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/membership/meetings-activities/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "DELIST_MEMBER",
              }) ? (
                <li className="nav-item">
                  <Link
                    onClick={handleActiveLink}
                    to="/membership/delist"
                    className="nav-link"
                  >
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Delisted Member(s)
                    </p>
                  </Link>
                </li>
              ) : null}

            </ul>
          </li>
        ) : null}

        {/* end view membership moudle */}
      </React.Fragment>
    );
  }
}
export default MembershipAside;
