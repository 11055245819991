const initState = {
  status: null,
  message: null,
  errors: [],
  spinner: false,
  users: [],
  usersCount: null,
  members: [],
  gideons: [],
  auxilliaries: [],
  staffs: [],
  staffs_1: [],
  friends: [],
  camp_members: [],
  report: {},
  delisted: [],
  delistedhistory: [],
};

const membershipReducer = (state = initState, action) => {
  switch (action.type) {
    case "CLEAR_USERS_ERRORS":
      return {
        ...state,
        errors: null,
        spinner: false,
        status: null,
        message: null,
      };
    case "ERRORS_CREATE_KIT_M":
      return {
        ...state,
        status: "error",
        message: "invalid Data",
      };
    case "LOAD_USERS_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "CLEAR_USERS_REPORT":
      return {
        ...state,
        report: {},
      };
    case "SET_MEM_CONVEN_REPORT":
      return {
        ...state,
        report: action.payload,
      };
    case "UNLOAD_USERS_SPINNER":
      return {
        ...state,
        spinner: false,
      };
    case "SET_SUCCESS_MESSAGE_REMITA":
      // const { status, message } = action.error;
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_GET_ALL_USERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_VERIFY_REMITA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_NEW_ACTIVITIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_PENDING_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_MEMBER_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_NOTIFICATIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_PENDING_RELOCATION_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_AUTHORIZED_RELOCATION_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_PENDING_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_REJECTED_RENEWAL":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_VERIFIED_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_CANCELLED_ACTIVITIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_COMPLETED_CAMP_ACTIVITY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "COMPLETED_CAMP_ACTIVITY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_SET_REJECTED_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_PAY_CONTRIBUTION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_PAY_RENEWAL":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_CAMP_ACTIVITIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_RECEIVED_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ALL_MEMBERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ALL_MEMBERS_CAMP":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };

    case "GET_ALL_USERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        users: action.resMessage.payload.users,
      };
    case "GET_ALL_USERS_COUNT":
      // console.log(action.resMessage.payload);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        usersCount: action.resMessage.payload.count,
      };
    case "GET_ALL_MEMBERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        members: action.resMessage.payload.members,
      };
    case "GET_ALL_STAFFS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        staffs_1: action.resMessage.payload.staffs,
      };
    case "ERRORS_GET_ALL_STAFFS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "GET_ALL_MEMBERS_CAMP":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        camp_members: action.resMessage.payload.members,
      };
    case "ERRORS_CREATE_USERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_TRANSFER_REQUEST_DET":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_AUXILLARY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_STAFF":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_RELOCATION_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_PENDING_CAMP_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_PENDING_ACTIVITY_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_SCRIPTURE_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_PENDING_CAMP_DEPOT_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };

    case "ERRORS_CREATE_FRIENDS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_RAISE_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GENERATE_REPORTS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "GENERATE_REPORTS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "REJECT_ACTIVITY_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "AUTHORIZE_ACTIVITY_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_PROFILE":
      console.log(action.resMessage);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_UPDATE_PROFILE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_REJECT_ACTIVITY_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_AUTHORIZE_ACTIVITY_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SUPPLY_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ALL_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_PENDING_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "RAISE_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "SUPPLY_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_UPDATE_USERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_USERS_AUX":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_USERS_STA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_USERS_FRI":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_ACKNOWLEGDE_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_ACKNOWLEGDE_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_DELETE_CAMP_ACTIVITY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_CAMP_ACTIVITIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_REJECT_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };

    case "ERRORS_CREATE_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CHANGE_PASSWORD":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_DELETE_NOTIFICATIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "CREATE_USERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_USERS_GIDS":
      let former_gids_2 = state.gideons;
      let single_gid2 = action.resMessage.payload.member;
      // console.log(single_gid2, former_gids_2);
      if (former_gids_2.length) {
        former_gids_2 = [...former_gids_2, single_gid2];
      } else {
        former_gids_2 = [];
      }
      // console.log(former_staffs_2);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        gideons: former_gids_2,
      };
    case "SET_ALL_DELISTED":
      return {
        ...state,
        delisted: action.resMessage.payload.members,
    };
    case "ERRORS_SET_ALL_DELISTED":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
    };
    case "SET_ALL_DELISTED_HISTORY":
      return {
        ...state,
        delistedhistory: action.resMessage.payload.members,
    };
    case "ERRORS_SET_ALL_DELISTED_HISTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
    };
    case "RESTORE_DELIST_MEMBER":
      let former_delisted = state.delisted;
      let single_delisted = action.resMessage.payload.member;
      if (former_delisted.length) {
        former_delisted = former_delisted.filter((delisted) => delisted.id !== action.resMessage.payload.member.id);
        console.log(action.resMessage.payload.member)
        //Restore to parent state
        if(action.resMessage.payload.member.type == "G")
        {
          //Restore to Gid
          let origGid = state.gideons;
          let gid = origGid.filter((gideon) => gideon.id !== action.resMessage.payload.member.id);

          return {
            ...state,
            status: action.resMessage.status,
            message: action.resMessage.message,
            delisted: former_delisted,
            gideons: gid,
          };

        }
        else{
          //Restore to Aux
          let origAux = state.auxilliaries;
          let aux = origAux.filter((auxilliaries) => auxilliaries.id !== action.resMessage.payload.member.id);

          return {
            ...state,
            status: action.resMessage.status,
            message: action.resMessage.message,
            delisted: former_delisted,
            auxilliaries: aux,
          };
        }
      } else {
        former_delisted = [];
          return {
            ...state,
            status: action.resMessage.status,
            message: action.resMessage.message,
            delisted: former_delisted,
          };
      }
    case "ERRORS_RESTORE_MEMBER":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
    };
    case "DELIST_GID_MEMBER":
      let former_gids_3 = state.gideons;
      let single_gid3 = action.resMessage.payload.member;
      if (former_gids_3.length) {
        former_gids_3 = former_gids_3.filter((gideon) => gideon.id !== action.resMessage.payload.member.id);
      } else {
        former_gids_3 = [];
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        gideons: former_gids_3,
      };
    case "ERRORS_DELIST_GID_MEMBER":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
    };
    case "DELIST_AUX_MEMBER":
      let former_gids_4 = state.auxilliaries;
      let single_gid4 = action.resMessage.payload.member;
      if (former_gids_4.length) {
        former_gids_4 = former_gids_4.filter((auxilliaries) => auxilliaries.id !== action.resMessage.payload.member.id);
      } else {
        former_gids_4 = [];
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        auxilliaries: former_gids_4,
      };
    case "ERRORS_DELIST_AUX_MEMBER":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
    };
    case "DELETE_CAMP_ACTIVITY":
      // console.log(action.resMessage);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_NEW_ACTIVITIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_CAMP_ACTIVITIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CHANGE_PASSWORD":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ACKNOWLEGDE_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "GET_NOTIFICATIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "DELETE_NOTIFICATIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ACKNOWLEGDE_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "REJECT_CONTRIBUTIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };

    case "CREATE_KITS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_AUXILLARY":
      let former_auxs_2 = state.auxilliaries;
      let single_aux2 = action.resMessage.payload.member;
      // console.log(single_aux2, former_auxs_2);
      if (former_auxs_2.length) {
        former_auxs_2 = [...former_auxs_2, single_aux2];
      } else {
        former_auxs_2 = [];
      }
      // console.log(former_staffs_2);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        auxilliaries: former_auxs_2,
      };
    case "CREATE_STAFF":
      let former_staffs_2 = state.staffs_1;
      let single_staff2 = action.resMessage.payload.staff;
      // console.log(single_staff2, former_staffs_2);
      if (former_staffs_2.length) {
        former_staffs_2 = [...former_staffs_2, single_staff2];
      } else {
        former_staffs_2 = [];
      }
      // console.log(former_staffs_2);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        staffs_1: former_staffs_2,
      };

    case "CREATE_RELOCATION_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "PAY_CONTRIBUTION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "PAY_RENEWAL":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ACKNOWLEGDE_MERCHANDISE_RECEPTION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_FRIENDS":
      let former_friends_2 = state.friends;
      let single_friend2 = action.resMessage.payload.friend;
      // console.log(single_friend2, former_friends_2);
      if (former_friends_2.length) {
        former_friends_2 = [...former_friends_2, single_friend2];
      } else {
        former_friends_2 = [];
      }
      // console.log(former_staffs_2);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        friends: former_friends_2,
      };

    case "UPDATE_USERS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_USERS_GID":
      let former_gideons = state.gideons;
      let single_member_gid = action.resMessage.payload.member;

      if (former_gideons.length) {
        let index = former_gideons.findIndex(
          (item) => item.id === single_member_gid.id
        );
        let former_member = former_gideons[index];
        let new_member = { ...former_member, ...single_member_gid };
        former_gideons[index] = new_member;
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        gideons: former_gideons.length ? former_gideons : [],
      };

    case "UPDATE_USERS_FRI":
      let former_friends = state.friends;
      let single_member_fri = action.resMessage.payload.friend;

      if (former_friends.length) {
        let index = former_friends.findIndex(
          (item) => item.id === single_member_fri.id
        );
        let former_friend = former_friends[index];
        let new_friend = { ...former_friend, ...single_member_fri };
        former_friends[index] = new_friend;
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        friends: former_friends.length ? former_friends : [],
      };
    case "UPDATE_USERS_STA":
      let former_staffs_1 = state.staffs_1;
      let single_staff = action.resMessage.payload.staff;

      if (former_staffs_1.length) {
        let index = former_staffs_1.findIndex(
          (item) => item.id === single_staff.id
        );
        let former_staff = former_staffs_1[index];
        let new_staff = { ...former_staff, ...single_staff };
        former_staffs_1[index] = new_staff;
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        staffs_1: former_staffs_1.length ? former_staffs_1 : [],
      };
    case "UPDATE_USERS_AUX":
      let former_auxilliaries = state.auxilliaries;
      let single_member = action.resMessage.payload.member;

      if (former_auxilliaries.length) {
        let index = former_auxilliaries.findIndex(
          (item) => item.id === single_member.id
        );
        let former_member = former_auxilliaries[index];
        let new_member = { ...former_member, ...single_member };
        former_auxilliaries[index] = new_member;
      }
      // console.log(former_auxilliaries, single_member);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        auxilliaries: former_auxilliaries.length ? former_auxilliaries : [],
      };
    case "SET_ALL_GIDEONS":
      //console.log(action.resMessage);
      return {
        ...state,
        gideons: action.resMessage.payload.members,
      };

    case "SET_ALL_AUXILLIARIES":
      // console.log(action.resMessage);
      return {
        ...state,
        auxilliaries: action.resMessage.payload.members,
      };
    case "SET_ALL_STAFFS":
      // console.log(action.resMessage);
      return {
        ...state,
        staffs: action.resMessage.payload.staffs,
      };
    case "SET_ALL_FRIENDS":
      // console.log(action.resMessage);
      return {
        ...state,
        friends: action.resMessage.payload.friends,
      };
    case "ERRORS_SET_ALL_GIDEONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_USERS_GID":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_AUXILLIARIES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_STAFFS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_ACKNOWLEGDE_MERCHANDISE_RECEPTION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_FRIENDS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_MEMBER":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_CAMP_ACTIVITY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };

    default:
      return state;
  }
};

export default membershipReducer;
