import React, { Component } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  signout,
  setPermissionsErrors,
} from "../../../../store/actions/authActions";

import {
  getNotification,
  removeNofication,
  getAllMembers,
  getAllStaffs,
  getAllUsers,
  getAllGideons,
  getAllAuxilliaries,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
// import "moment-timezone";
import Moment from "react-moment";
import $ from "jquery";
// import { withRouter } from "react-router-dom";
// import moment from "moment";
import { storage_type, user_auth_details, dehash } from "./../../../../config";
import {
  getAllCamps,
  getAllRegions,
  getAllAreas,
  getAllStates,
  getAllBibles,
} from "../../../../store/actions/preferencesActions";

class Header extends Component {
  state = {
    notifications: "",
    cart: [],
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.cart !== prevState.cart) {
      return {
        cart: nextProps.cart,
      };
    }
    return null;
  }
  handleSignOut = (e) => {
    e.preventDefault();
    this.props.signout();
  };
  componentDidMount() {
    // alert("here");
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      // console.log(user);
      this.setState({ auth_type: user.type }, () => {
        if (this.state.auth_type === "S") {
          this.props.getAllAuxilliaries();
          this.props.getAllGideons();
          this.props.getAllStaffs();
          this.props.getAllMembers();
          this.props.getAllUsers();

          this.props.getAllCamps();
          this.props.getAllAreas();
          this.props.getAllRegions();
          this.props.getAllStates();
          this.props.getAllBibles();
        } else {
          this.props.getAllCamps();
          this.props.getAllAreas();
          this.props.getAllRegions();
          this.props.getAllStates();
          this.props.getAllBibles();
        }
      });
      this.props.getNotification().then((res) => {
        if (res.status === "success") {
          const notifications = res.payload.notifications;
          this.setState({ notifications }, () => {
            // this.getTag();
          });
        }
      });
    }
  }
  removeNofication = (e, id) => {
    $("#tag-drop").addClass("show");
    $("#tag-drop2").addClass("show");
    this.setState({
      notifications: this.state.notifications.filter(
        (notification) => notification.id !== id
      ),
    });
    // console.log(id);
    this.props.removeNofication(id);
  };
  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };
  goForward = (e) => {
    e.preventDefault();
    this.props.history.goForward();
  };

  render() {
    const { isAuth, spinner } = this.props;
    const { notifications } = this.state;
    return (
      <React.Fragment>
        {!isAuth ? <Redirect to="/login" /> : null}
        {/* Navbar */}
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" href="#!">
                <i className="fas fa-bars" />
              </a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <Link to="/" className="nav-link">
                Dashboard
              </Link>
            </li>
            {this.state.auth_type && this.state.auth_type !== "S" ? (
              <li className="nav-item  d-sm-inline-block">
                <Link to="/store" className="nav-link">
                  Store
                </Link>
              </li>
            ) : null}
            <li className="nav-item  d-sm-inline-block">
              <Link
                onClick={this.goBack}
                to="#!"
                className="nav-link"
                title="Go back"
              >
                <i className="fas fa-arrow-circle-left text-primary"></i>
              </Link>
            </li>
            <li className="nav-item  d-sm-inline-block">
              <Link
                onClick={this.goForward}
                to="#!"
                className="nav-link"
                title="Go forward"
              >
                <i className="fas fa-arrow-circle-right text-primary "></i>
              </Link>
            </li>
          </ul>
          {/* SEARCH FORM */}

          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto">
            {spinner ? <Spinner position="center" /> : null}
            {/* Messages Dropdown Menu */}
            {notifications && notifications.length > 0 ? (
              <li className="nav-item dropdown " id="tag-drop2">
                <a className="nav-link" data-toggle="dropdown" href="#!">
                  <i className="far fa-bell" />
                  <span className="badge badge-danger navbar-badge">
                    {notifications.length}
                  </span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-right "
                  style={{ height: "300px", overflowY: "scroll" }}
                  id="tag-drop"
                >
                  {notifications &&
                    notifications.map((notification, index) => (
                      <React.Fragment key={index}>
                        <a
                          href="#!"
                          className="dropdown-item my-item"
                          onClick={(e) => {
                            this.removeNofication(e, notification.id);
                          }}
                        >
                          {/* Message Start */}
                          <div className="media">
                            <div className="media-body">
                              <h3 className="dropdown-item-title my-item">
                                {notification.data.subject}
                                <span className="float-right text-sm text-danger">
                                  <i className="fas fa-times " />
                                </span>
                              </h3>
                              <p className="text-sm font-italic">
                                {notification.data.body}
                              </p>
                              <p className="text-sm text-muted">
                                <i className="far fa-clock mr-1" />

                                <Moment format="MMMM Do YYYY, h:mm:ss a">
                                  {notification.created_at}
                                </Moment>
                              </p>
                            </div>
                          </div>
                          {/* Message End */}
                        </a>
                        <div className="dropdown-divider" />
                      </React.Fragment>
                    ))}
                </div>
              </li>
            ) : null}

            {/* start cart */}
            {this.state.auth_type && this.state.auth_type !== "S" ? (
              <li className="nav-item dropdown">
                <Link to="/cart" className="nav-link">
                  <i className="fas fa-cart-plus" />
                  {this.state.cart.length > 0 ? (
                    <span className="badge badge-info navbar-badge">
                      {this.state.cart.length}
                    </span>
                  ) : null}
                </Link>
              </li>
            ) : null}
            {/* end cart */}

            {/* Notifications Dropdown Menu */}
            <li className="nav-item dropdown">
              <a className="nav-link" data-toggle="dropdown" href="#!">
                <i className="fas fa-th-large" />
                {/* <span className="badge badge-warning navbar-badge">15</span> */}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="dropdown-divider" />
                <Link to="/" className="dropdown-item">
                  <i className="fas fa-window-restore mr-2" /> Dashboard
                </Link>
                <div className="dropdown-divider" />
                <Link to="/personal/change-password" className="dropdown-item">
                  <i className="fas fa-user-lock mr-2" /> Change Password
                </Link>
                <div className="dropdown-divider" />
                <Link to="/personal/profile" className="dropdown-item">
                  <i className="fas fas fa-users-cog mr-2" /> My Profile
                </Link>
                {/* <div className="dropdown-divider" />
                <a href="#!" className="dropdown-item">
                  <i className="fas fa-tasks mr-2" /> My Task
                </a> */}
                {/* <div className="dropdown-divider" />
                  <a href="#!" className="dropdown-item">
                    <i className="fas fa-cog mr-2" /> Settings
                  </a> */}
                <div className="dropdown-divider" />
                <a href="#!" className="dropdown-item dropdown-footer">
                  <button
                    className="btn  btn-upper btn-sm btn-bold"
                    onClick={this.handleSignOut}
                  >
                    Sign out
                  </button>
                </a>
              </div>
            </li>
          </ul>
        </nav>

        {/* /.navbar */}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth,
    spinner: state.auth.spinner,
    cart: state.tstore.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => dispatch(signout()),
    getNotification: () => getNotification(dispatch),
    removeNofication: (id) => removeNofication(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    getAllCamps: () => dispatch(getAllCamps()),
    getAllAreas: () => dispatch(getAllAreas()),
    getAllRegions: () => dispatch(getAllRegions()),
    getAllStates: () => dispatch(getAllStates()),
    getAllMembers: () => dispatch(getAllMembers()),
    getAllBibles: () => dispatch(getAllBibles()),
    getAllAuxilliaries: () => dispatch(getAllAuxilliaries()),
    getAllGideons: () => dispatch(getAllGideons()),
    getAllStaffs: () => dispatch(getAllStaffs()),
    getAllUsers: () => dispatch(getAllUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
