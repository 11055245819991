import React from "react";
import Layout from "./components/private/Layout";
import Login from "./components/public/auth/Login";
import ForgetPassword from "./components/public/auth/ForgetPassword";
import ResetPassword from "./components/public/auth/ResetPassword";
import requireAuth from "./hoc/reqireAuth";
import alreadyAuth from "./hoc/alreadyAuth";

import { BrowserRouter, Switch, Route } from "react-router-dom";


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/reset-password/" component={alreadyAuth(ResetPassword)} />
        <Route
          path="/forget-password"
          component={alreadyAuth(ForgetPassword)}
        />
        <Route path="/login" component={alreadyAuth(Login)} />
        <Route path="/" component={requireAuth(Layout)} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
